/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Group from "components/svgs/group"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import ImageText from "components/image-text"
import List from "components/list"
import { TileInfo, TileLink, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query ParticipationPageQuery {
    heroImage: file(relativePath: { eq: "hero/participation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    foodSystemsImage: file(relativePath: { eq: "hero/food.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    garden: file(relativePath: { eq: "participation/garden.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    speech: file(relativePath: { eq: "participation/speech.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laptops: file(relativePath: { eq: "participation/laptops.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    signing: file(relativePath: { eq: "participation/signing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ParticipationPage = ({ data }) => (
  <Layout
    pageTitle="Participation"
    description="Mohawk offers a wide range of opportunities for students to gain experience in sustainability and support climate action."
  >
    <Title icon={<Group />}>Participation</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          As a college, Mohawk helps prepare Future Ready students by
          emphasizing hands-on learning. Mohawk offers a wide range of
          opportunities for students to gain experience in sustainability and
          support climate action.
        </Styled.p>
        <Styled.p>
          This includes providing volunteering, work placements, and on-campus
          employment opportunities, as well as special events, training
          workshops and conferences.
        </Styled.p>
        <Styled.p>
          Student and employee participation helps integrate sustainability into
          the campus culture and encourages behaviour change. Volunteering, work
          placements and other forms of participation give Mohawk students the
          ability to deepen and apply their understanding of sustainability
          principles.
        </Styled.p>
      </div>
      <ImageRow>
        <Img className="wide" fluid={data.garden.childImageSharp.fluid} />
        <Img fluid={data.speech.childImageSharp.fluid} />
        <Img fluid={data.laptops.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Group />}>
          <Fragment>
            "Greening Orientation" activities to engage new and returning
            students on Day One
          </Fragment>
          <Fragment>
            Co-curricular and volunteer opportunities offered through Campus to
            Community
          </Fragment>
          <Fragment>
            Annual Sustainability Initiatives Fund (SIF) Idea Bank campaign
            encourages students to share their campus sustainability ideas
          </Fragment>
          <Fragment>
            Capstone projects and experiential learning, using the campus as a
            living lab
          </Fragment>
          <Fragment>
            Partnership with WWF-Canada's Living Planet @ Campus program to
            support student leaders
          </Fragment>
          <Fragment>
            Student co-op placements and employment with sustainability-focused
            companies
          </Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h2 as="span">6000+</Styled.h2>
            <br />
            people engaged through events, talks, tours and outreach in 2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h2 as="span">70</Styled.h2>
            <br />
            students volunteered for sustainability initiatives in 2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h2 as="span">150+</Styled.h2>
            <br />
            youth attended the Bay Area Climate Change Youth Summit in March
            2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h2 as="span">18</Styled.h2>
            <br />
            campus sustainability initiatives implemented from student ideas
            since 2015
          </Styled.p>
        </TileInfo>
      </TileWrapper>

      <ImageText image={data.signing.childImageSharp.fluid}>
        <Styled.h2>Greening Orientation</Styled.h2>
        <Styled.p>
          Orientation is among the first experiences Mohawk students have on
          campus, setting the stage for future sustainable behaviours. More than
          1,000 incoming students are provided with a reusable water bottle and
          sign the "plastic-free" pledge, committing to reduce single-use
          plastics. A waste sorting tent was introduced at the 2019 orientation,
          improving waste diversion at the event and providing an opportunity
          for incoming students to learn the habit of sorting their waste.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink to="/food" image={data.foodSystemsImage.childImageSharp.fluid}>
        Food Systems
      </TileLink>
    </Container>
  </Layout>
)

export default ParticipationPage
