import React from "react"

const Group = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="68"
    viewBox="0 0 81 68"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M0 38.106c.15-.657.288-1.327.45-1.97 1.725-6.9 7.476-11.511 14.515-11.6 3.35-.037 6.713-.012 10.076-.012h1.026c.212 3.954 1.65 7.315 4.463 10.158-4.438 1.478-7.827 4.132-10.214 8.074-2.4 3.954-2.726 8.3-2.45 12.887C11.663 55.29 5.775 54.001 0 52.156v-14.05z"
    ></path>
    <path
      fill="currentColor"
      d="M60.584 57.07c0 2.136-.025 4.258.013 6.394.012.568-.2.834-.675 1.061-3.013 1.428-6.201 2.249-9.477 2.78-9.651 1.554-19.065.391-28.354-2.426-1.5-.455-1.5-.455-1.5-2.022 0-3.79-.05-7.58.025-11.358.175-7.846 6.3-14.316 14.052-14.657 4.1-.176 8.226-.151 12.327.026 5.838.252 11.514 5.091 12.952 10.89.375 1.517.5 3.109.612 4.676.113 1.528.025 3.083.025 4.637z"
    ></path>
    <path
      fill="currentColor"
      d="M63.323 55.795c0-1.744.075-3.323-.013-4.89-.437-7.39-5.076-13.544-11.977-15.983-.187-.063-.375-.151-.675-.265 2.763-2.83 4.25-6.178 4.426-10.145h2.95c2.213 0 4.426-.013 6.639 0 8.739.037 15.29 6.658 15.315 15.49.012 3.74-.013 7.467.025 11.207 0 .606-.213.871-.713 1.111-3.15 1.466-6.463 2.313-9.864 2.818-1.963.29-3.938.43-6.113.657zM28.867 24.044c.025-6.545 5.264-11.826 11.727-11.814 6.439.013 11.727 5.358 11.714 11.864 0 6.558-5.313 11.877-11.801 11.84-6.476-.064-11.665-5.345-11.64-11.89zM20.016 0c6.451.013 11.677 5.281 11.702 11.763 0 .518-.15.872-.55 1.213-2.538 2.186-4.163 4.94-4.788 8.263-.1.543-.4.796-.838 1.023-4.1 2.098-8.164 2.01-11.99-.543-3.825-2.552-5.588-6.305-5.225-10.929C8.802 4.65 13.953-.013 20.016 0zm39.955 0c6.189-.025 11.44 4.953 11.727 11.093.3 6.495-4.275 11.915-10.564 12.534-1.925.19-3.788-.076-5.563-.86-.263-.113-.55-.48-.6-.757-.713-4.233-2.776-7.594-6.276-10.032-.488-.341-.425-.72-.388-1.15C48.845 4.7 53.883.025 59.971 0z"
    ></path>
  </svg>
)

export default Group
